module.exports = [{
      plugin: require('/Users/benedict/Repositories/Expressed/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-143066813-1"},
    },{
      plugin: require('/Users/benedict/Repositories/Expressed/node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/Users/benedict/Repositories/Expressed/src/components/shared/layout.js"},
    },{
      plugin: require('/Users/benedict/Repositories/Expressed/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
