import React, { Component } from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { stack as MobileMenu } from "react-burger-menu"
import HamburgerMenu from "react-hamburger-menu"

export default class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      menuOpenState: false,
    }

    this.isMenuOpen = this.isMenuOpen.bind(this)
  }

  handleStateChange(state) {
    this.setState({ menuOpenState: state.isOpen })
  }

  closeMenu() {
    this.setState({ menuOpenState: false })
  }

  toggleMenu(e) {
    e.preventDefault()
    this.setState({ menuOpenState: !this.state.menuOpenState })
  }

  isMenuOpen(state) {
    this.setState({ menuOpenState: state.isOpen })
  }

  render() {
    return (
      <div className="header">
        <div id="click">
          <HamburgerMenu
            isOpen={this.state.menuOpenState}
            menuClicked={e => this.toggleMenu(e)}
            width={32}
            height={23}
            strokeWidth={3}
            rotate={0}
            color="black"
            borderRadius={0}
            animationDuration={0.5}
          />
        </div>
        <MobileMenu
          pageWrapId={"page-wrap"}
          outerContainerId={"outer-container"}
          right
          width={"100%"}
          isOpen={this.state.menuOpenState}
          onStateChange={this.isMenuOpen}
          customCrossIcon={false}
          customBurgerIcon={false}
          noOverlay
        >
          <AniLink
            paintDrip
            duration={0.75}
            hex="#000080"
            to="/"
            onClick={() => this.closeMenu()}
          >
            Home
          </AniLink>

          <AniLink
            paintDrip
            to="/about"
            duration={0.75}
            hex="#000080"
            onClick={() => this.closeMenu()}
          >
            About
          </AniLink>

          <AniLink
            paintDrip
            to="/work"
            duration={0.75}
            hex="#000080"
            onClick={() => this.closeMenu()}
          >
            Work
          </AniLink>

          <AniLink
            paintDrip
            to="/blog"
            duration={0.75}
            hex="#000080"
            to="/blog"
            onClick={() => this.closeMenu()}
          >
            Writing
          </AniLink>
        </MobileMenu>
      </div>
    )
  }
}
