/* eslint-disable */

import React from "react"
import PropTypes from "prop-types"
import "bulma/css/bulma.css"
import Header from "./header"
import lifecycle from "react-pure-lifecycle"

import "../css/layout.css"
import "../css/custom.css"
import "../css/mobile.css"
import "../css/menu.css"
import "../css/header.css"

const methods = {
  componentDidMount() {
    if (window && typeof document == "object" && window.VANTA) {
      window.VANTA.WAVES({
        el: "#waves",
        color: "rgb(8, 17, 145)",
        shininess: 20.0,
        waveHeight: 4.0,
        waveSpeed: 0.7,
        zoom: 1.48,
      })
    }
  },
}

const Layout = ({ children }) => (
  <div style={{ position: "relative" }} className="">
    <Header />
    <div
      className="container is-widescreen is-fullheight main-wrapper"
      id="page-wrap"
      style={{ minHeight: "100vh" }}
    >
      <main
        className="main container is-widescreen"
        style={{ minHeight: "initial !important", marginTop: "10vh" }}
      >
        {children}
      </main>
    </div>
    <footer className="b-footer container is-widescreen">
      <span style={{ fontSize: "12px" }}>
        © {new Date().getFullYear()} Ben Chachaj. Built with
        {` `}
        <a href="https://www.gatsbyjs.org">Gatsby</a>
      </span>
    </footer>
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default lifecycle(methods)(Layout)
